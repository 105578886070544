import React from 'react';
import { Grid, TextField, Button, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Typography } from '@material-ui/core';
import { DATEFORMAT, API_URL } from '../Components/Global';
import { Redirect } from 'react-router-dom';

export default class Create extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            weekcontainer: {
                week: 27,
                year: 2020,
                days: []
            },
            goback: false
        };
    }

    componentDidMount() {
        fetch(API_URL + '/schedule/initializecreate')
            .then(response => response.json())
            .then(result => this.setState({ weekcontainer: result }));
    }

    handleChange = (e) => {
        const temp = this.state.weekcontainer;
        if (e.target.id === 'weeknumber') {
            temp.week = e.target.value;
        }
        else {
            temp.year = e.target.value;
        }
        this.setState({ weekcontainer: temp });
        if ((e.target.id === 'weeknumber' && e.target.value > 0) ||
            (e.target.id === 'year' && e.target.value.length === 4)) {
            fetch(API_URL + '/schedule/initializecreate/' + this.state.weekcontainer.week + '/' + this.state.weekcontainer.year)
                .then(response => response.json())
                .then(result => this.setState({ weekcontainer: result }));
        }
    };

    handleTimeChange = (e) => {
        const container = this.state.weekcontainer;
        const days = container.days;

        if (e.target.id.includes('Start')) {
            days[e.target.id[0]].start = e.target.value;
        }
        else {
            days[e.target.id[0]].end = e.target.value;
        }

        this.setState({ weekcontainer: container });
    }

    createWeek = (e) => {
        var postObject = [];
        postObject.push(this.state.weekcontainer);

        fetch(API_URL + '/schedule', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postObject)
        })
            .then(response => response.json())
            .then(result => this.goBack());
    }

    goBack = () => {
        this.setState({ goback: true });
    }

    render() {
        if (this.state.goback) {
            return <Redirect push to="/" />;
        }
        return (
            <TableContainer component={Paper}>
                <Table size="small" aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    id="weeknumber"
                                    label="Ugenummer"
                                    value={this.state.weekcontainer.week}
                                    onChange={this.handleChange}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="year"
                                    label="År"
                                    value={this.state.weekcontainer.year}
                                    onChange={this.handleChange}
                                />
                            </TableCell>
                        </TableRow>
                        {this.state.weekcontainer.days.map((day, index) => {
                            return <TableRow key={index}>
                                <TableCell colSpan={2}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} align='center'>
                                            <Typography variant='h6'>
                                                {DATEFORMAT.format(new Date(day.date))}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} align='left'>
                                            <TextField
                                                id={index + 'Start'}
                                                label='Start'
                                                type="time"
                                                value={this.state.weekcontainer.days[index].start}
                                                onChange={this.handleTimeChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} align='right'>
                                            <TextField
                                                id={index + 'End'}
                                                label='End'
                                                type="time"
                                                value={this.state.weekcontainer.days[index].end}
                                                onChange={this.handleTimeChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        })}
                        <TableRow>
                            <TableCell>
                                <Button onClick={this.createWeek}>Opret</Button>
                            </TableCell>
                            <TableCell align='right'>
                                <Button onClick={this.goBack}>Tilbage</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}