import React, { Fragment } from 'react';
import { TableContainer, Paper, Table, TableHead, TableCell, TableBody, TableRow, Grid, Typography, Button } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { DATEFORMAT, API_URL } from '../Components/Global';


export default class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            edit: 0,
        };
    }

    componentDidMount() {
        this.fetchSchedule();
    }

    fetchSchedule() {
        fetch(API_URL + '/schedule')
            .then(response => response.json())
            .then(result => this.setState({ data: result }));
    }

    deleteWeek = (id) => {
        fetch(API_URL + '/schedule/' + id, {
            method: 'DELETE'
        })
            .then(() => this.fetchSchedule());
    }

    render() {
        if (this.state.edit !== 0) {
            return <Redirect push to={'/update/' + this.state.edit} />;
        }
        return <Grid container spacing={2}>
            {this.state.data.map((week) => {
                return <Fragment key={week.id}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={3} align='center'><Typography variant='h5'>Uge {week.week} - {week.year}</Typography></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Dato</TableCell>
                                        <TableCell>Fra</TableCell>
                                        <TableCell>Til</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {week.days.map((row) => {
                                        return <TableRow key={row.id}>
                                            <TableCell>{DATEFORMAT.format(new Date(row.date))}</TableCell>
                                            <TableCell>{row.start}</TableCell>
                                            <TableCell>{row.end}</TableCell>
                                        </TableRow>
                                    })}
                                    <TableRow>
                                        <TableCell>
                                            <Button onClick={() => this.setState({ edit: week.id })}>Redigér</Button>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align='right'>
                                            <Button onClick={() => this.deleteWeek(week.id)}>Slet</Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Fragment>

            })}
            <Grid item>
                <Link to='/create'>
                    <Button>Tilføj uge</Button>
                </Link>
            </Grid>
        </Grid>
    }
}
