import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Main from './Views/Main';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Test from './Views/Test';
import Create from './Views/Create';
import Update from './Views/Update';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="xs">
          <Typography component="div">
            <Router>
              <Switch>
                <Route path='/update/:id' component={Update}>
                  {/* <Update /> */}
                </Route>
                <Route path='/create'>
                  <Create />
                </Route>
                <Route path='/test'>
                  <Test />
                </Route>
                <Route path='/'>
                  <Main />
                </Route>
              </Switch>
            </Router>
          </Typography>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
