import React from 'react';
import { Link } from 'react-router-dom';


export default function Test() {
    return (
    <div>
        <Link to='/'>Go back</Link>
        <div>TESTING!!!</div>
    </div>
    )
}